/* src/app/layout/components/menu/menu.component.scss */
:host ::ng-deep kendo-panelbar {
  padding: 0 6px;
}
:host ::ng-deep kendo-panelbar:nth-of-type(1) {
  margin-top: 10px;
}
:host ::ng-deep kendo-panelbar > kendo-panelbar-item > .k-link .menu-icon-wrapper.active,
:host ::ng-deep kendo-panelbar > kendo-panelbar-item > .k-link .title.active {
  color: #4e1289 !important;
}
:host ::ng-deep kendo-panelbar .k-link {
  cursor: pointer !important;
  border-radius: 3px;
  transition: all 0.25s ease-in-out;
}
:host ::ng-deep kendo-panelbar .k-link:hover {
  background: #e0e0e0 !important;
}
mat-icon {
  height: 18px;
  width: 18px;
  position: relative;
  top: 2px;
  margin-right: 4px;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
ul li {
  padding: 3px 8px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 1px;
}
.divider {
  width: 80%;
  margin: 4px auto;
  height: 1px;
  background: #e0e0e0;
}
.divider:nth-last-of-type(1) {
  display: none;
}
/*# sourceMappingURL=menu.component.css.map */
