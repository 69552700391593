import { DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { importProvidersFrom, inject } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { HeaderInterceptor, RetryInterceptor, coreProviders } from '@cue/admin-core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { provideDataStores } from '@cue/admin-data';
import { ConfigService } from '@cue/admin-core';
import { gridProviders } from '@cue/admin-grid';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { provideQuillConfig } from 'ngx-quill';
import { accountProviders, accountRoutes } from './app/account';
import { provideGoogleAnalytics } from '@cue/admin-google-analytics';

import { layoutRoutes } from './app/layout/layout.routes';
import { treelistProviders } from './app/treelist/treelist.provider';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cache=' + new Date().toISOString());
}

export function appConfig(service: ConfigService) {
  return {
    providers: [
      provideGoogleAnalytics({
        keyFactory: () => {
          const configService = inject(ConfigService);
          return configService.value.googleAnalyticsEnabled &&
            configService.value.googleAnalyticsKey != null
            ? configService.value.googleAnalyticsKey
            : undefined;
        },
      }),
      provideDataStores(),
      provideRouter(
        [
          ...layoutRoutes,
          ...accountRoutes,
          {
            path: '**',
            redirectTo: 'notFound',
          },
        ],
        withComponentInputBinding(),
      ),
      provideHttpClient(withInterceptorsFromDi()),
      provideAnimationsAsync(),
      coreProviders,
      accountProviders,
      gridProviders,
      treelistProviders,
      provideQuillConfig({}),
      importProvidersFrom(
        NotificationModule,
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
          },
        }),
      ),
      { provide: NZ_I18N, useValue: en_US },
      DatePipe,
      {
        provide: ConfigService,
        useValue: service,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HeaderInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: RetryInterceptor,
        multi: true,
      },
    ],
  };
}
