/* src/app/layout/components/layout/layout.component.scss */
.header {
  background-color: #ffffff;
  height: 55px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 100;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Basic Sans", sans-serif;
  font-weight: 700;
  font-size: 1.3125rem;
}
.hamburger {
  width: 20px;
  margin-left: 20px;
  cursor: pointer;
}
.hamburger .line {
  width: 100%;
  height: 3px;
  border-radius: 2px;
  background: #000000;
  transition: all 0.25s ease;
}
.hamburger .line:not(:nth-last-of-type(1)) {
  margin-bottom: 3px;
}
.hamburger .line:nth-of-type(1) {
  width: 80%;
}
.hamburger .line:nth-of-type(2) {
  width: 100%;
}
.hamburger .line:nth-of-type(3) {
  width: 60%;
}
.hamburger:hover .line {
  background: #4e1289;
}
.hamburger:hover .line:nth-of-type(1) {
  width: 60%;
}
.hamburger:hover .line:nth-of-type(2) {
  width: 40%;
}
.hamburger:hover .line:nth-of-type(3) {
  width: 100%;
}
.logoSvg {
  margin: 8px 20px;
  display: block;
  vertical-align: middle;
  height: 2.625rem;
  pointer-events: none;
}
#layout {
  overflow: auto;
  position: absolute;
  top: 55px;
  bottom: 0;
  height: calc(100% - 55px);
  width: 100%;
  background: #e5e5e5;
}
#layout.menu-collapsed #layoutLeft {
  width: 0px;
}
#layout.menu-collapsed #layoutRight {
  width: calc(100% - 24px * 2);
}
#layoutLeft {
  float: left;
  width: 250px;
  height: 100%;
  overflow-y: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  position: relative;
  z-index: 5;
  transition: all 0.25s ease;
}
#layoutRight {
  position: relative;
  float: left;
  width: calc(100% - 250px - 24px * 2);
  height: calc(100% - 20px * 2);
  padding: 20px 24px;
  transition: all 0.25s ease;
}
/*# sourceMappingURL=layout.component.css.map */
