import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import {
  RefreshTreelistDataAction,
  TreelistDataRefreshSuccessAction,
  TreelistDataRefreshFailedAction,
  TreelistChangeSelections,
  TreelistClearSelections,
  TreelistSetSelections,
  TreelistSetSelectionsData,
} from '../actions/treelist.actions';
import { Treelist } from '../models/treelist.model';
import { errors } from '@telerik/kendo-intl';

export interface TreelistsState extends EntityState<Treelist> {}

export const adapter: EntityAdapter<Treelist> = createEntityAdapter<Treelist>();

export const initialState: TreelistsState = adapter.getInitialState();

const treelistReducer = createReducer(
  initialState,
  on(RefreshTreelistDataAction, (state, { id, settings, payload }) => {
    const oldState = state.entities[id];
    return adapter.upsertOne(
      {
        id,
        loading: true,
        data: oldState
          ? oldState.data
          : {
              data: [],
              //total: 0
            },
        state: settings ? settings : oldState.state,
        payload: payload ? payload : oldState?.payload,
      },
      state,
    );
  }),
  // tslint:disable-next-line:no-shadowed-variable
  on(
    TreelistDataRefreshSuccessAction,
    (state, { id, treeListResponse /*, total*/, treeListErrors }) => {
      const oldState = state.entities[id];
      return adapter.upsertOne(
        {
          id,
          loading: false,
          data: {
            data: treeListResponse || [],
            //total
          },
          state: oldState.state,
          errors: treeListErrors,
          payload: oldState.payload,
        },
        state,
      );
    },
  ),
  on(TreelistDataRefreshFailedAction, (state, { id }) => {
    const stateTreelistBefore = state.entities[id];
    return adapter.upsertOne(
      {
        id,
        loading: false,
        data: {
          data: [],
          //total: 0
        },
        state: stateTreelistBefore.state,
        errors: {
          errorCode: -1,
          errorDescription: 'Something is wrong, please repeate action.',
          success: false,
        },
        payload: null,
      },
      state,
    );
  }),
  on(TreelistChangeSelections, (state, { id }) => {
    const oldState = state.entities[id];
    return adapter.upsertOne(
      {
        id,
        loading: false,
        data: oldState.data,
        state: oldState.state,
        errors: oldState.errors,
        payload: oldState.payload,
      },
      state,
    );
  }),
  on(TreelistClearSelections, (state, { id }) => {
    const oldState = state.entities[id];
    return adapter.upsertOne(
      {
        id,
        loading: false,
        data: oldState.data,
        state: oldState.state,
        errors: oldState.errors,
        payload: {
          showSelected: false,
          selections: [],
        },
      },
      state,
    );
  }),
  on(TreelistSetSelections, (state, { id, newSelections }) => {
    const oldState = state.entities[id];
    return adapter.upsertOne(
      {
        id,
        loading: false,
        data: oldState?.data,
        state: oldState?.state,
        errors: oldState?.errors,
        payload: {
          ...oldState?.payload,
          selections: [...newSelections],
        },
      },
      state,
    );
  }),
  on(TreelistSetSelectionsData, (state, { id, newSelectionsData }) => {
    const oldState = state.entities[id];
    return adapter.upsertOne(
      {
        id,
        loading: false,
        data: oldState?.data,
        state: oldState?.state,
        errors: oldState?.errors,
        payload: {
          ...oldState?.payload,
          selectionsData: [...newSelectionsData],
        },
      },
      state,
    );
  }),
);

export function reducer(state: TreelistsState | undefined, action: Action) {
  return treelistReducer(state, action);
}
