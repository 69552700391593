import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { exhaustMap, map, concatMap, catchError, withLatestFrom } from 'rxjs/operators';
import { TreelistDataService } from '../services/treelist-data.service';
import {
  TreelistDataRefreshFailedAction,
  TreelistDataRefreshSuccessAction,
  RefreshTreelistDataAction,
} from '../actions/treelist.actions';
import { TreelistsState } from '../reducers/treelist.reducers';
import { translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { Observable, of } from 'rxjs';
import { getTreelistById } from '../selectors/treelist.selectors';

export function withBetterLatestFrrom<TSource, TSecond>(
  second: (input: TSource) => Observable<TSecond>,
) {
  return function latestFromIsSuxx(source: Observable<TSource>) {
    return Observable.create((subscriber) => {
      const subscription = source
        .pipe(concatMap((action) => of(action).pipe(withLatestFrom(second(action)))))
        .subscribe(
          (value) => {
            try {
              subscriber.next(value);
            } catch (err) {
              subscriber.error(err);
            }
          },
          (err) => subscriber.error(err),
          () => subscriber.complete(),
        );
      return subscription;
    }) as Observable<[TSource, TSecond]>;
  };
}

@Injectable()
export class TreelistsEffects {
  constructor(
    private treelistsService: TreelistDataService,
    private actions$: Actions,
    private store: Store<TreelistsState>,
  ) {}

  loadTreelistData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshTreelistDataAction),
      withBetterLatestFrrom((x) => this.store.select(getTreelistById(x.id))),
      concatMap(([action, treelist]) => {
        const settings = action.settings ? action.settings : treelist.state;
        const addition = action.addition ? action.addition : null;
        const payload = action.payload ? action.payload : null;
        return this.treelistsService
          .fetchData(action.urlPostfix, settings as any, addition, payload)
          .pipe(
            map((x) => {
              const hasGroups = settings.group && settings.group.length;
              return TreelistDataRefreshSuccessAction({
                id: action.id,
                treeListResponse: hasGroups
                  ? translateDataSourceResultGroups(x.data)
                  : x.data /*, total: x.total*/,
                treeListErrors: x.errors,
              });
            }),
            catchError((_) => {
              return [TreelistDataRefreshFailedAction({ id: action.id })];
            }),
          );
      }),
    ),
  );
}
