<ng-container *ngFor="let item of menu; trackBy: trackMenuItemsFn">
    <kendo-panelbar
        *ngIf="item.hideCalendar !== true && (item.wip !== true || isWIP)"
        [appWIPHide]="item.wip === true"
        [appAnalyticsHide]="item.analytics === true"
    >
        <kendo-panelbar-item
            (click)="onMenuItemExpand(item, !item.selected)"
            title="{{ item.title | translate }}"
            [expanded]="item.selected"
            *ngIf="canOpen(item)"
        >
            <ng-template kendoPanelBarItemTitle>
                <div [appWIPHide]="item.wip === true">
                    <!-- TODO: Na .menu-icon-wrapper pridat ".active", kdyz bude subitem aktivní -->
                    <span
                        *ngIf="item.icon"
                        class="menu-icon-wrapper"
                        [class.active]="anySubActive(item) && !item.selected"
                    >
                        <mat-icon svgIcon="{{ item.icon }}" class="menu-icon"></mat-icon>
                    </span>
                    <!-- TODO: Na .title pridat ".active", kdyz bude subitem aktivní -->
                    <span
                        class="title"
                        [class.active]="anySubActive(item) && item.selected == false"
                    >
                        {{ item.title | translate }}
                    </span>
                </div>
            </ng-template>
            <ng-template kendoPanelBarContent>
                <ul>
                    <ng-container *ngFor="let subItem of item.items">
                        <li
                            [appWIPHide]="subItem.wip === true"
                            [appAnalyticsHide]="subItem.analytics === true"
                            (click)="$event.stopPropagation()"
                            class="menu-item-main menu-item"
                            routerLinkActive="menu-item-selected"
                            [attr.data-cy]="'menu-' + subItem.path + '_button'"
                            [routerLink]="subItem.path"
                            *ngIf="
                                (canOpen(subItem) && subItem.wip !== true) ||
                                (canOpen(subItem) && isWIP)
                            "
                        >
                            <span class="menu-text" [translate]="subItem.title"> </span>
                        </li>
                    </ng-container>
                </ul>
            </ng-template>
        </kendo-panelbar-item>
    </kendo-panelbar>
    <div *ngIf="canOpen(item)" class="divider"></div>
</ng-container>
