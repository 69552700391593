import { tap, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { AccountService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class UserRoleGuard {
  constructor(
    public router: Router,
    private accountService: AccountService,
  ) {}

  getUserRoleStore(): Observable<number> {
    return this.accountService.getUserRoleStore();
  }

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    const roles =
      route && route.data.roles && route.data.roles.length > 0 ? route.data.roles : null;

    if (roles) {
      return this.getUserRoleStore().pipe(
        switchMap((userRoleId) => {
          return userRoleId
            ? of(roles.includes(userRoleId))
            : this.accountService.getUserRole().pipe(
                tap((roleId) => {
                  this.accountService.setUserRoleStore(roleId);
                  if (!roles.includes(roleId)) {
                    this.router.navigate([CONSTANTS.paths.accessDenied]);
                  }
                }),
                map((roleId) => (roles.includes(roleId) ? true : false)),
              );
        }),
      );
    }
    return true;
  }
}
