import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '@cue/admin-account';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserIsLoggedInGuard {
  constructor(
    public router: Router,
    private accountService: AccountService,
  ) {}

  isLoggedIn(): Observable<boolean> {
    return this.accountService.amILogged();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.isLoggedIn().pipe(
      tap((x) => {
        if (!x) {
          this.router.navigate(['account/login'], {
            queryParams: {
              return_url: state.url,
            },
          });
        }
      }),
    );
  }
}
